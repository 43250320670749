export function populateDesc(model, list) {
    const description = list.find((result) => {
        return result.model === model;
    }).description;
    return description;
}

export function populateAbv(frame_color, list) {
    const frame_initials = list.find((result) => {
        return result.frame_color === frame_color;
    }).frame_initials;
    return frame_initials;
}

export function populateFabCol(fabric_number, list) {
    const fabric_color = list.find((result) => {
        return result.fabric_number === fabric_number
    }).fabric_color;
    return fabric_color;
}

export function populateFabBr(fabric_number, list) {
    const fabric_brand = list.find((result) => {
        return result.fabric_number === fabric_number
    }).fabric_brand;
    return fabric_brand;
}

// number is working, need to get sequential down ie
// choose brand => next select choose brand's colors =>
    // number populates
export function populateFabNum(fabric_brand, fabric_color, list) {
    const fabric_number = list.find((result) => {
        return result.fabric_brand === fabric_brand && result.fabric_color === fabric_color
    }).fabric_number;
    console.log(fabric_number);
    return fabric_number;
}

export function populateBaseFeat(base, list) {
    const features = list.find((result) => {
        return result.base === base
    }).features;
    return features;
}