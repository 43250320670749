import React, { useState, useEffect } from "react";
import { getListOf } from "../helpers/model.helpers";
import {
  populateAbv,
  populateDesc,
  populateFabBr,
  populateFabCol,
  populateFabNum
} from "../helpers/populate.helpers";
import { validate } from "../helpers/validation.helpers";
import { ventAbbr } from "../helpers/vent.helpers";
import Pricing from "./pricing";
import "./modelPicker.css";

export default function ModelPicker({
  updateSubTotals,
  updateQuantities,
  updateModelData,
}) {
  const [list, setList] = useState([]);
  const [modelNumber, setModelNumber] = useState("");
  const [modelDesc, setModelDesc] = useState("");
  const [ventType, setVentType] = useState("");
  const [frameFinish, setFrameFinish] = useState("");
  const [frameAbv, setFrameAbv] = useState("");
  const [fabricNumber, setFabricNumber] = useState("");
  const [fabricColor, setFabricColor] = useState("");
  const [fabricBrand, setFabricBrand] = useState("");
  const [testFabNum, setTestFabNum] = useState("");

  console.log(testFabNum);

  async function getModel() {
    try {
      let response = await fetch("https://tempo-order-writer.vercel.app/api/models", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let result = await response.json();
      setList(result);
    }
    catch(err) {
      alert(err);
    }
  }

  let models = getListOf(list, "model");
  let vents = getListOf(list, "vent_type");
  let finishes = getListOf(list, "frame_color");
  let fabrics = getListOf(list, "fabric_number");
  let fabricBrands = getListOf(list, "fabric_brand");
  console.log(fabricBrands);
  // let fabricColors = getListOf(list, "fabric_color");
  let valids = getListOf(list, "item_number");
  let itemNumber = [modelNumber, ventAbbr(ventType), frameAbv, fabricNumber].filter((part) => part).join("-");
  let valid = validate(valids, itemNumber);
  
  const validModelPush = (tempItemNumber) => {
    if (validate(valids, tempItemNumber)) {
      updateModelData(tempItemNumber, modelDesc)
    }
  }

  useEffect(() => {
    getModel();
  }, []);

  useEffect(() => {
    validModelPush(itemNumber);
  }, [itemNumber]);

  // need to format all number outputs

  return (
    <div className="model-table">
      <form className="model-list" action="">
        <div className="picker-section">
          <h4>Model Selection</h4>
          <label htmlFor="model-number">Model Number</label>
          <div className="dropdown detail">
            <select
              name="model-number"
              id="model-number"
              value={modelNumber}
              onChange={(e) => {
                setModelNumber(e.target.value);
                setModelDesc(populateDesc(e.target.value, list));
                }}
            >
              <option value="">Select One</option>
              {models.map((model, idx) => {
                return (
                  <option key={model + idx} value={model}>
                    {model}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="description">Item Description</label>
          <div className="detail">
            <p className="model-description">{modelDesc}</p>
          </div>
          <label htmlFor="vent-type">Vent Type</label>
          <div className="dropdown detail">
            <select
              name="vent-type"
              id="vent-type"
              value={ventType}
              onChange={(e) => {
                setVentType(e.target.value);
              }}
            >
              <option value="">Select One</option>
              {vents.map((vent, idx) => {
                return (
                  <option key={vent + idx} value={vent}>
                    {vent}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="picker-section">
          <h4>Frame Selection</h4>
          <label htmlFor="frame-finish">Frame Finish</label>
          <div className="dropdown detail">
            <select
              name="frame-finish"
              id="frame-finish"
              value={frameFinish}
              onChange={(e) => {
                setFrameFinish(e.target.value);
                setFrameAbv(populateAbv(e.target.value, list));
              }}
            >
              <option value="">Select One</option>
              {finishes.map((finish, idx) => {
                return (
                  <option key={finish + idx} value={finish}>
                    {finish}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="frame-abv">Frame Abv.</label>
          <div className="detail">
            <input
              type="text"
              name="frame-abv"
              id="frame-abv"
              value={frameAbv}
              readOnly
            />
          </div>
        </div>
        <div className="picker-section">
          <h4>Fabric Selection</h4>
          <label htmlFor="fabric-number">Fabric Number</label>
          <div className="dropdown detail">
          {/* need to refactor dropdowns for selects based on brand + color to get number */}
            <select
              name="fabric-number"
              id="fabric-number"
              value={fabricNumber}
              onChange={(e) => {
                setFabricNumber(e.target.value);
                let tempFabricColor = populateFabCol(e.target.value, list);
                let tempFabricBrand = populateFabBr(e.target.value, list);
                // add this to new select, use fabricBrand, e.target.value (fabricColor)
                let tempFabricNumber = populateFabNum(tempFabricBrand, tempFabricColor, list);
                setFabricColor(tempFabricColor);
                setFabricBrand(tempFabricBrand);
                setTestFabNum(tempFabricNumber);
              }}
            >
              <option value="">Select One</option>
              {fabrics.map((fabric, idx) => {
                return (
                  <option key={fabric + idx} value={fabric}>
                    {fabric}
                  </option>
                );
              })}
            </select>
          </div>
          <label htmlFor="fabric-color">Fabric Color</label>
          <div className="detail">
            <input
              type="text"
              name="fabric-color"
              id="fabric-color"
              value={fabricColor}
              readOnly
            />
            <select 
              name="" 
              id=""
              value={fabricBrands}
              // onChange={}
              >
                <option value="">Select One</option>
                {fabricBrands.map((fabricBrand, idx) =>{
                  return (
                    <option key={fabricBrand + idx} value={fabricBrand}>
                      {fabricBrand}
                    </option>
                  )
                })}
            </select>
          </div>
          <label htmlFor="fabric-brand">Fabric Brand</label>
          <div className="detail">
            <input
              type="text"
              name="fabric-brand"
              id="fabric-brand"
              value={fabricBrand}
              readOnly
            />
          </div>
        </div>
        <div className="model-validation">
          <h4>Item Validation</h4>
          <label htmlFor="item-number">Item Number</label>
          <input
            type="text"
            name="item-number"
            id="item-number"
            value={itemNumber}
            readOnly
          />
          <label htmlFor="model-valid">Valid Model</label>
          <input
            type="text"
            name="model-valid"
            id="model-valid"
            value={valid ? "Valid" : "Invalid"}
            readOnly
          />
          <p id="validity-note">Note: Model will show as <strong>Invalid</strong> until all selections are made. If <strong>Invalid</strong> is still shown, please adjust your selections.</p>
        </div>
      </form>
      <Pricing updateSubTotals={updateSubTotals} updateQuantities={updateQuantities}/>
    </div>
  );
}

