import { useState } from "react";
import { getExtCost } from "../helpers/pricing.helpers";
import "./pricing.css";

export default function Pricing({updateSubTotals, updateQuantities}) {
  const [quantity, setQuantity] = useState("");
  const [eaCost, setEaCost] = useState("");
  const [disc, setDisc] = useState("");
  const [extCost, setExtCost] = useState("");

  console.log(extCost);

  return (
    // price list and discount lookup
    // discount based on input customer name or container order
    // pull in price from db, discount if applicable, set ext cost based on quantity updates

    <div className="pricing-container">
      <div className="pricing-section">
      <label htmlFor="quantity">Quantity</label>
      <input type="text" name="quantity" id="quantity" value={quantity} onChange={(e) => {
        setQuantity(e.target.value)
      }} />
      </div>
      <div className="pricing-section">
      <label htmlFor="ea-cost">Cost (ea)</label>
      <input type="text" name="ea-cost" id="ea-cost" value={eaCost} onChange={(e) => {
        // add a function here to change numbers with commas and allow editing before switching back
        setEaCost(e.target.value)
      }} />
      </div>
      <div className="pricing-section">
      <label htmlFor="discount-percent">Discount %</label>
      <input type="text" name="discount-percent" id="discount-percent" value={disc} onChange={(e) => {
        // same function as here
        setDisc(e.target.value)
        setExtCost(getExtCost(quantity, eaCost, e.target.value));
      }} />
      </div>
      <div className="pricing-section">
      <label htmlFor="ext-cost">Extended Cost</label>
      <input type="text" name="ext-cost" id="ext-cost" value={Number(extCost).toFixed(2)} readOnly/>
      </div>
      <button className="confirm-btn" onClick={() => {
        updateSubTotals(extCost);
        updateQuantities(quantity);
        }}
        >Confirm</button>
    </div>
  );
}
