import { forwardRef, useState } from "react";
import { useLocation } from "react-router-dom";
import logoBrown from "../assets/tp-brown.png";
import "./orderReview.css";

const OrderReview = forwardRef(function OrderReview({ handleOrderPrint }, ref) {
  const { state } = useLocation();
  console.log(state);
  const initialTotal = state.gTotal;
  console.log(state.subTotalsM);
  console.log(state.subTotalsB);
  console.log(initialTotal);
  const [data, setData] = useState(state);
  console.log(data);
  const [orderTotal, setOrderTotal] = useState(initialTotal);

  const handleDelete = (index, e, rowType) => {
    if (rowType === "modelData") {
    console.log(index);
    setData({...data, modelData: data.modelData.filter((idx) => {
      return idx !== index;
    }), quantityM: data.quantityM.filter((idx) => {
      return idx !== index;
    }), subTotalsM: data.subTotalsM.filter((idx) => {
      return idx !== index;
    }), countM: data.countM - 1
  });
  setOrderTotal(orderTotal - data.subTotalsM[index]);
} else if (rowType === "baseData") {
  setData({...data, baseData: data.baseData.filter((idx) => {
      return idx !== index;
    }), quantityB: data.quantityB.filter((idx) => {
      return idx !== index;
    }), subTotalsB: data.subTotalsB.filter((idx) => {
      return idx !== index;
    }), countB: data.countB - 1
  });
  setOrderTotal(orderTotal - data.subTotalsB[index]);
}
}

  const numberModels = data.countM;
  const modelTableRowArr = [];
  for (let i = 0; i < numberModels; i++) {
    const newModelObject = {
      modelRowID: i,
      modelNumber: data.modelData[i][0],
      modelDescription: data.modelData[i][1],
      quantityOrdered: data.quantityM[i],
      modelSubTotal: data.subTotalsM[i],
    };
    modelTableRowArr.push(newModelObject);
  }
  console.log(modelTableRowArr);

  const numberBases = data.countB;
  const baseTableRowArr = [];
  for (let i = 0; i < numberBases; i++) {
    const newBaseObject = {
      baseRowID: i,
      baseNumber: data.baseData[i][0],
      baseDescription: data.baseData[i][1],
      quantityOrdered: data.quantityB[i],
      baseSubTotal: data.subTotalsB[i],
    };
    baseTableRowArr.push(newBaseObject);
  }

  const fullAddress = [
    state.customerData.address + ", ",
    state.customerData.city + ", ",
    state.customerData.state + " ",
    state.customerData.zip,
  ];

  return (
      <div className="overview" ref={ref}>
          <img src={logoBrown} className="logo-brown" alt="brown Tempo Patio logo" />
        <div id="fiuo">
          <p><strong>For Internal Use Only:</strong></p>
          <label htmlFor="approval__date">Approval Date</label>
          <input name="approval__date" id="approval__date" />
        </div>
        <p>Order Number: <strong id="order-number">{state.orderNumber}</strong></p>
        <div className="order-meta">
          <p>Order Date: {state.customerData.order__date}</p>
          <p>Company/Customer Name: {state.customerData.co__name}</p>
          <p>Company/Customer Address: {fullAddress}</p>
          <p>Contact Name: {state.customerData.contact}</p>
          <p>Contact Phone Number: {state.customerData.phone}</p>
          <p>Contact Email: {state.customerData.email}</p>
          <p>Requested Ship Date: {state.customerData.ship__date}</p>
          <p>Order Notes: {state.customerData.order__notes}</p>
        </div>
        <table className="order-data">
          <thead>
            <tr className="data-header">
              <th>Model Number</th>
              <th>Model / Base Description</th>
              <th>Quantity</th>
              <th>Extended Cost</th>
            </tr>
          </thead>
          <tbody>
            {modelTableRowArr.map((lineItem, idx) => {
              console.log(idx);
              return (
                <tr key={idx}>
                  <td>{lineItem.modelNumber}</td>
                  <td>{lineItem.modelDescription}</td>
                  <td>{lineItem.quantityOrdered}</td>
                  <td>{lineItem.modelSubTotal}</td>
                  <button onClick={e => {handleDelete(idx, e, "modelData")}} className="delete-btn">Delete</button>
                </tr>
              );
            })}
            {baseTableRowArr.map((lineItem, idx) => {
              return (
                <tr key={idx}>
                  <td>{lineItem.baseNumber}</td>
                  <td>{lineItem.baseDescription}</td>
                  <td>{lineItem.quantityOrdered}</td>
                  <td>{lineItem.baseSubTotal}</td>
                  <button onClick={e => {handleDelete(idx, e, "baseData")}} className="delete-btn">Delete</button>
                </tr>
              );
            })}
          </tbody>
        </table>
        <p className="cost">Total Cost: ${orderTotal}</p>
        <button onClick={handleOrderPrint} className="print-btn">Print Order</button>
      </div>
  );
});

export default OrderReview;
