import { useState, useEffect } from "react";
import { getListOf } from "../helpers/model.helpers";
import { populateBaseFeat } from "../helpers/populate.helpers";
import Pricing from "./pricing";
import "./basePicker.css";

export default function BasePicker({updateSubTotals, updateQuantities, updateBaseData}) {
  const [list, setList] = useState([]);
  const [base, setBase] = useState("");
  const [features, setFeatures] = useState("");

  async function getBase() {
    // do you want a base? btn
    // update database to include features and fits
    try {
      let response = await fetch("http://localhost:5526/api/bases/");
      let result = await response.json();
      setList(result);
    }
    catch(err) {
      console.log(err);
    }
  }

  let bases = getListOf(list, "base");

  useEffect(() => {
    getBase();
  }, []);

  return (
    <div className="base-table">
      <div className="base-section">
        <h4>Base Selection</h4>
        <label htmlFor="bases">Base Number</label>
        <div className="dropdown detail">
          <select
            name="bases"
            id="bases"
            value={base}
            onChange={(e) => {
              setBase(e.target.value);
              setFeatures(populateBaseFeat(e.target.value, list));
              updateBaseData(e.target.value, populateBaseFeat(e.target.value, list));
            }}
          >
            <option value="">Select One</option>
            {bases.map((base, idx) => {
              return (
                <option key={base + idx} value={base}>
                  {base}
                </option>
              );
            })}
          </select>
        </div>
        {/* divide into description and fits */}
        <label htmlFor="base-features">Base Features</label>
        <div className="detail">
          <p className="base-description">{features}</p>
        </div>
      </div>
      <Pricing updateSubTotals={updateSubTotals} updateQuantities={updateQuantities}/>
    </div>
  );
}

